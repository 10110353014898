const baseFieldStyle = { field: { borderRadius: 'md', fontSize: '14px' } }

export const Input = {
    parts: ['field', 'addon'],
    // Styles for the base style
    baseStyle: {},
    // Styles for the size variations
    sizes: {
        lg: baseFieldStyle,
        md: baseFieldStyle,
        sm: baseFieldStyle,
        xs: baseFieldStyle,
    },
    // The default `size` or `variant` values
    defaultProps: {
        size: 'lg',
        focusBorderColor: 'gray.500',
    },
}
