import { Box, Button, Container, Heading, Link, Stack, Text } from '@chakra-ui/react'
import type { ReactNode } from 'react'

export interface ErrorPageProps {
    buttonLabel?: string
    heading: string
    redirectUrl?: string
    description: ReactNode
    showReloadButton?: boolean
}

/**
 * ErrorPage
 * Displays Error if nothing provides then will displlay a page not found
 */
export function ErrorPage({ buttonLabel, heading, redirectUrl, description, showReloadButton }: ErrorPageProps) {
    return (
        <Container maxW={'3xl'}>
            <Stack as={Box} align="start">
                <Heading fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} lineHeight={'110%'}>
                    {heading}
                </Heading>
                {typeof description === 'string' ? <Text color={'gray.500'}>{description}</Text> : description}

                <Stack direction={'column'} spacing={3} align={'start'} position={'relative'}>
                    {showReloadButton === true ? (
                        <Button
                            colorScheme={'purple'}
                            px={6}
                            _hover={{
                                bg: 'green.500',
                            }}
                            onClick={() => window.location.reload()}
                        >
                            {buttonLabel ?? 'Reload'}
                        </Button>
                    ) : (
                        <Button as={Link} href={redirectUrl ?? '/'} colorScheme={'purple'}>
                            {buttonLabel ?? 'Back Home'}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Container>
    )
}
