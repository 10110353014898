import type { StyleConfig} from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react'

import { Button } from './components/Button'
import { Input } from './components/Input'
import { Select } from './components/Select'
import { Textarea } from './components/Textarea'

export const theme = extendTheme({
    config: {
        useSystemColorMode: false,
        initialColorMode: 'light',
    },
    fonts: {
        body: 'Inter, sans-serif',
        heading: 'Inter, serif',
    },
    breakpoints: {
        '2xs': '320px', // 1 Columns
        xs: '480px', // 2 Columns
        sm: '640px', // 3 Columns
        md: '860px', // 4 Columns
        lg: '1024px', // 5 Columns
        xl: '1200px', // 6 Columns
        '2xl': '1312px', // 7 Columns
    },
    components: {
        Button,
        Input,
        FormLabel: {
            baseStyle: { mb: 0 },
        },
        FormError: {
            parts: ['text', 'icon'],
            baseStyle: { text: { mt: 1 } },
        },
        Select,
        Link: {
            baseStyle: {
                textDecoration: 'none'
            }
        } satisfies StyleConfig,
        Textarea,
    },
})
